import React, { useEffect, useState } from "react";
import "../siteInfo/SiteInfo.jsx";
import {
  LabelText,
  ButtonInput,
  TextInput,
  SelectInput,
  RadioInput,
} from "@emerson/dynamic-ui-public";
import "./DeviceDetails.css";
import useOnScreen from "../../customHooks/UseOnScreen.js";
import DeleteIcon from "../../icons/icon--global_delete.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosAddCircle } from "react-icons/io"
const DeviceDetails = (props) => {
  const {
    setActiveInStep,
    serviceTypeName,
    serviceName,
    sysInfoDetails,
    userDeviceList,
    setSelectedDeviceList,
    userSiteList,
    selectedDeviceList,
    setSelectedSite,
    selectedSite,
    setSystem,
    setStepMemoize,
    deviceData,
    setDeviceData,
    setReason,
    reason,
    setAdditionalReturnDetail,
    additionalReturnDetail,
    prefDevDate,
    setPrefDevDate,
    setEndUser,
    endUser,
    setEndUserSiteAddress,
    endUserSiteAddress,
    setAddDetails,
    endUserCountry,
    setEndUserCountry,
    endUserStateProvince,
    setEndUserStateProvince,
    endUserCity,
    setEndUserCity,
    endUserPostalZipCode,
    setEndUserPostalZipCode,
  } = props;
  const [maxLimitError, setMaxLimitError] = useState(false);
  const today = new Date();
  const [deliveryDate, setDate] = useState("");
  const [reqDifferentEndUser, setReqDifferentEndUser] = useState("");
  const [showEndUserFields, setShowEndUserFields] = useState(false);
  const [errorSiteNotFoundMsg, setErrorSiteNotFoundMsg] = useState("");

  const setEnableEndUserFields =(val) => {
     setShowEndUserFields(val);
     setReqDifferentEndUser("no");
  } 

  const  setErrorMessage = (msg) => {
   setErrorSiteNotFoundMsg(msg); 
   setTimeout(() => {
      setErrorSiteNotFoundMsg("");
    }, 5000)
  }

  const checkDuplicateSerialNum =(data) =>{
    // Check for duplicate serial numbers but only if there are more than 1 serial number and value is not empty
    if (data.length > 1) {
    const serialNumbers = data
    .map((item) => item.serialNumber)
    .filter((serialNumber) => serialNumber !== "" && serialNumber !== null)
    .map((serialNumber) => serialNumber.trim().toLowerCase()); // Convert to lowercase to make it case-insensitive
      const uniqueSerialNumbers = new Set(serialNumbers);
      return serialNumbers.length !== uniqueSerialNumbers.size;
    }
  }

  const selectDateHandler = (d) => {
    setDate(d);
    setPrefDevDate(d === "" || !d ?"": d.toDateString());
  };

  const [data, setData] = useState([
    {
      id: 1,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 2,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 3,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 4,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    {
      id: 5,
      serialNumber: "",
      modelCode: "",
      manufacturer: "",
      tagNumber: "",
      quality: "",
    },
    // {
    //   id: 6,
    //   serialNumber: "",
    //   modelCode: "",
    //   manufacturer: "",
    //   tagNumber: "",
    //   quality: "",
    // },
    // {
    //   id: 7,
    //   serialNumber: "",
    //   modelCode: "",
    //   manufacturer: "",
    //   tagNumber: "",
    //   quality: "",
    // },
    // {
    //   id: 8,
    //   serialNumber: "",
    //   modelCode: "",
    //   manufacturer: "",
    //   tagNumber: "",
    //   quality: "",
    // },
    // {
    //   id: 9,
    //   serialNumber: "",
    //   modelCode: "",
    //   manufacturer: "",
    //   tagNumber: "",
    //   quality: "",
    // },
    // {
    //   id: 10,
    //   serialNumber: "",
    //   modelCode: "",
    //   manufacturer: "",
    //   tagNumber: "",
    //   quality: "",
    // },
  ]);
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [sysProject, setSysProject] = useState("");
  const [sysModel, setSysModel] = useState("");
  const [sysSerialNumber, setSysSerialNumber] = useState("");

   //visibilty check
  //  useEffect(() => {
  //   setPrefSerDate(deliveryDate === "" || !deliveryDate ?"": deliveryDate.toDateString());
  // }, [deliveryDate]);

  useEffect(() => {
    setAdditionalReturnDetail(false);
  }, []);
  
  const productOptions = (ipArr) => {
    return ipArr?.map((product, ind) => {
      return {
        greyedOut: false,
        label: product.serial_number,
        title: product.serial_number,
        value: product,
      };
    });
  };
  const handleSysData = (event) => {
    const { name, value } = event.target;
    setSystem({
      ...system,
      [name]: value,
    });
  };
  const siteOptions = (ipArr) => {
    return ipArr?.map((customer, ind) => {
      return {
        greyedOut: false,
        label: customer.customer_name,
        title: customer,
        value: customer.customer_name,
      };
    });
  };
  const populateRow = (inputData) => {
    setData((prevData) => {
      const newData = [...prevData];
      const emptyRowIndex = newData.findIndex(
        (row) =>
          !row.serialNumber &&
          !row.modelCode &&
          !row.manufacturer &&
          !row.tagNumber &&
          !row.quality
      );

      if (emptyRowIndex !== -1) {
        newData[emptyRowIndex] = {
          ...newData[emptyRowIndex],
          serialNumber: inputData.serial_number,
          modelCode: inputData.base_model,
          manufacturer: inputData.brand,
          tagNumber:
            inputData.tag_no === null ? "Not Available" : inputData.tag_no,
          quality:
            serviceTypeName === "Return A Product" ||
            serviceName === "Calibration"
              ? ""
              : newData[emptyRowIndex].quality,
        };
      }

      // Add a new empty row if needed and if it has less than 99 rows
      if (
        newData.filter(
          (row) =>
            !row.serialNumber &&
            !row.modelCode &&
            !row.manufacturer &&
            !row.tagNumber &&
            !row.quality
        ).length < 99
      ) {
        newData.push({
          id: newData.length + 1,
          serialNumber: "",
          modelCode: "",
          manufacturer: "",
          tagNumber: "",
          quality: "",
        });
      }

      return newData;
    });
  };

  const handleInputChange = (e, rowIndex, columnName) => {
    const { value } = e.target;
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;
      return newData;
    });
  };

  const handleAddDevice = () => {
    setData((prevData) => {
      const newData = [...prevData];
        // Add a new empty row if needed and if it has less than 99 rows
        if (
          newData.length < 99
        ) {
          newData.push({
            id: newData.length + 1,
            serialNumber: "",
            modelCode: "",
            manufacturer: "",
            tagNumber: "",
            quality: "",
          });
        }
        return newData;
      });
  };

  const handleDropdownChange = (e, rowIndex) => {
    const { value } = e;
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].quality = value;
      return newData;
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setData((prevData) => {
      const newData = prevData.filter((_, index) => index !== rowIndex);
      // Add a new empty row at the end if there are less than 99 rows
      if (newData.length < 99) {
        newData.push({
          id: newData.length + 1,
          serialNumber: "",
          modelCode: "",
          manufacturer: "",
          tagNumber: "",
          quality: "",
        });
      }
      return newData;
    });
  };
  const hasDataInRow = (row) => {
    return (
      row.serialNumber ||
      row.modelCode ||
      row.manufacturer ||
      row.tagNumber ||
      row.quality
    );
  };

  // visibility check
  const handleVisibilityChange = () => {
    setStepMemoize(4);
  };
  const ref = useOnScreen(handleVisibilityChange);
  //visibilty check

  useEffect(() => {
    setSelectedDeviceList(
      data.filter((obj) => {
        return !(
          obj.serialNumber === "" &&
          obj.modelCode === "" &&
          obj.manufacturer === "" &&
          obj.tagNumber === "" &&
          obj.quality === ""
        );
      })
    );
    setDeviceData(data);
  }, [data]);
  useEffect(() => {
    selectedDeviceList.length > 0 && setData(deviceData);
  }, []);
  return (
    <>
      <div ref={ref}>
        {sysInfoDetails.includes("Device") ||
        serviceTypeName === "Return A Product" ? (
          <div className="deviceServiceRequest">
            <br />
            <br />
            <LabelText
              text={
                serviceTypeName === "Return A Product"
                  ? !additionalReturnDetail ? "Service and Device Details": showEndUserFields? "End User Information" : "Service Request Details"
                  : "Device Details"
              }
              textClass="ddl-text--h3"
            />
            <br />
            
            <p hidden={showEndUserFields}>
            {!additionalReturnDetail   ? "Add up to 99 devices that need to be serviced." : "Just a few more things..."}
              <br />
              <br />
            </p>
            { !showEndUserFields ?   
           <> <div className="deviceServiceRequestBox">
              <div className="selectBoxdevice">
                {serviceTypeName === "Return A Product" && !additionalReturnDetail && (
                  <SelectInput
                    label="Select a Site"
                    labelClass="app-content-label"
                    className="siteDetail"
                    name="critical"
                    value={selectedSite?.customer_name}
                    onChange={(o, s, m, h) => {
                      setSelectedSite(o.title);
                    }}
                    options={siteOptions(
                      userSiteList.length === 0
                        ? [{ customer_name: "SITE NOT FOUND" }]
                        : [
                            ...userSiteList?.data
                              ?.filter((item, index, self) => {
                                return (
                                  index ===
                                  self.findIndex(
                                    (t) =>
                                      t.gdm_customer_number ===
                                        item.gdm_customer_number &&
                                      `${t.ebs_customer_name || ""}${
                                        t.oracle_customer_acc_number
                                          ? ` - ${t.oracle_customer_acc_number}`
                                          : ""
                                      }` ===
                                        `${item.ebs_customer_name || ""}${
                                          item.oracle_customer_acc_number
                                            ? ` - ${item.oracle_customer_acc_number}`
                                            : ""
                                        }`
                                  )
                                );
                              })
                              .map((item) => ({
                                ...item,
                                customer_name: `${
                                  item.ebs_customer_name || item.customer_name
                                }${
                                  item.oracle_customer_acc_number
                                    ? ` - ${item.oracle_customer_acc_number} -`
                                    : ""
                                }${
                                  item.address_line_1
                                    ? `${item.address_line_1}`
                                    : ""
                                }${item.city ? `, ${item.city}` : ""}${
                                  item.state_prov ? `, ${item.state_prov}` : ""
                                }${item.country ? `, ${item.country}` : ""}${
                                  item.world_area ? `, ${item.world_area}` : ""
                                }${
                                  item.postal_code
                                    ? `, ${item.postal_code}`
                                    : ""
                                }`,
                              })),
                            { customer_name: "OTHER" },
                          ]
                    )}
                    placeholder="PLEASE SELECT"
                    warningMsg=""
                    required
                  />
                )}
                {!additionalReturnDetail && (
                <SelectInput
                  label="Search Devices"
                  labelClass="app-content-label"
                  name="critical"
                  value={selectedDeviceList}
                  onChange={(e, c, d, f) => {
                    if (selectedDeviceList.length < 99) {
                      const selectedDevice = e.value;
                      const isDeviceAlreadySelected = selectedDeviceList.some(
                        (item) => item.unique_id === selectedDevice.unique_id
                      );

                      if (!isDeviceAlreadySelected) {
                        populateRow(selectedDevice); // Populate the first empty row with the selected device
                      }
                    } else {
                      setMaxLimitError(true);
                    }
                  }}
                  options={productOptions(
                    userDeviceList?.length > 0
                      ? userDeviceList?.filter((obj) =>
                          obj.device_category.includes("Measurement")
                        )
                      : [{ serial_number: "NO DEVICES FOUND" }]
                  )}
                  placeholder="Product"
                  warningMsg=""
                />)
              }
                {serviceTypeName === "Return A Product" && additionalReturnDetail && (
                  <div>
                  <div className="selectBoxdevice">
                  <div>
                    <span>
                      <LabelText
                        text="REQUESTED DELIVERY DATE (OPTIONAL)"
                        textClass="ddl-from-custom-label app-content-label"
                      />
                    </span>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      placeholderText="MM/DD/YYYY"
                      selected={prefDevDate ? prefDevDate : deliveryDate}
                      onChange={selectDateHandler}
                      minDate={today}
                      todayButton={"Today"}
                      className="app-content-text-width   app-content-text-width-normals"
                    />
                  </div>
                </div>
                  <TextInput
                    label="Reason for Return"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Please provide the reason here."
                  />
                  <RadioInput
            label="Are you requesting service on behalf of a different end user?"
           onChange={(e) => {
              setReqDifferentEndUser(e.target.value);
            }}
            options={[
              {
                label: "Yes",
                value: "yes",
              },
              {
                label: "No",
                value: "no",
              },
            ]}
            required
            size="small"
          />
                  </div>
                )}
              </div>
              {maxLimitError && (
                <p className="redError">You can't add more than 99 devices !</p>
              )}
              { !additionalReturnDetail && (
              <div className="scroll-bar-container">
              <table>
                <thead>
                  <tr className="theadCssOverride">
                    <th>
                      <div className="coloumn-design">
                        <LabelText
                          text="Serial Number"
                          textClass="ddl-text--h6"
                        />
                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <div className="coloumn-design">
                        <LabelText text="Model Code" textClass="ddl-text--h6" />

                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <div className="coloumn-design">
                        <LabelText
                          text="Manufacturer"
                          textClass="ddl-text--h6"
                        />
                        <span className="field-required-span">*</span>
                      </div>
                    </th>
                    <th>
                      <LabelText text="Tag Number" textClass="ddl-text--h6" />
                    </th>
                    {serviceName === "Calibration" && (
                      <th>
                        <LabelText text="Quality" textClass="ddl-text--h6" />
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "serialNumber")
                          }
                          value={row.serialNumber}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "modelCode")
                          }
                          value={row.modelCode}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "manufacturer")
                          }
                          value={row.manufacturer}
                        />
                      </td>
                      <td>
                        <TextInput
                          onChange={(e) =>
                            handleInputChange(e, rowIndex, "tagNumber")
                          }
                          value={row.tagNumber}
                        />
                      </td>
                      {serviceName === "Calibration" && (
                        <td>
                          <SelectInput
                            labelClass="app-content-label"
                            value={row.quality}
                            onChange={(o, s, m, h) => {
                              data[rowIndex].quality = h;
                            }}
                            options={[
                              {
                                greyedOut: false,
                                label: "Witness Inspection",
                                title: "Witness Inspection",
                                value: "witness_inspection",
                              },
                              {
                                greyedOut: false,
                                label: "5-point",
                                title: "5-point",
                                value: "5_point",
                              },
                              {
                                greyedOut: false,
                                label: "9-point",
                                title: "9-point",
                                value: "9_point",
                              },
                            ]}
                            warningMsg=""
                          />
                        </td>
                      )}
                      <td>
                        {hasDataInRow(row) && (
                          <img
                            onClick={() => handleDeleteRow(rowIndex)}
                            src={DeleteIcon}
                            alt="Icon"
                            className="deleteIcon"
                          />
                        )}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              )
}
            </div>
            <div>
             <br /> 
            <div style={{ display: `${additionalReturnDetail? "none":"flex"}`, alignItems: "center" }}>
              <button onClick={handleAddDevice} style={{ background: "none", border: "none", padding: 0, cursor: "pointer" }}>
                 <IoIosAddCircle style={{ fontSize: "24px", marginRight: "8px" }} />
               </button>
              <span>Add another device</span>
            </div>
            </div></> :
            <div className="endUserServiceRequestInfo endUserInfoDisplaySize">
            <TextInput
            label="Company"
            onChange={(e) => {
             setEndUser(e.target.value);
           }}
            required
           // value={sysSerialNumber}
          />
          <div className="endUserInfoMenu">
            <TextInput
            label="Site Address"
            onChange={(e) => {
             setEndUserSiteAddress(e.target.value);
           }}
            required
           // value={sysSerialNumber}
          />
           <TextInput
            label="Country"
            onChange={(e) => {
              setEndUserCountry(e.target.value);
            }}
            required
            //value={sysSerialNumber}
          />
           <TextInput
            label="State/Province"
            onChange={(e) => {
              setEndUserStateProvince(e.target.value);
            }}
            required
           // value={sysSerialNumber}
          />
          <TextInput
            label="City"
            onChange={(e) => {
              setEndUserCity(e.target.value);
            }}
            required
            //value={sysSerialNumber}
          />
          <TextInput
            label="Postal/Zip Code"
            onChange={(e) => {
              setEndUserPostalZipCode(e.target.value);
            }}
            required
           // value={sysProject}
          />
        </div>
        <TextInput
             label="ADDITIONAL DETAILS"
             onBlur={() => {}}
             onChange={(e) => {
               setAddDetails(e.target.value);
             }}
             rows={50}
           />
        </div>
            }
            <div>
              <br />
              <br />
              <p className="errorText">{errorSiteNotFoundMsg}</p>
              <ButtonInput
                btnType=""
                label="CONTINUE"
                onClick={() => {
                  selectedSite.customer_name === "SITE NOT FOUND"? setErrorMessage("Could not continue with the selected site. Please select a valid site.") :checkDuplicateSerialNum(data) ? setErrorMessage("The serial number you are trying to add has already been added to your request."): !additionalReturnDetail && serviceTypeName === "Return A Product" ? setAdditionalReturnDetail(true): reqDifferentEndUser==="yes"? setEnableEndUserFields(true) : setActiveInStep(3);
                  setSelectedDeviceList(
                    data.filter((obj) => {
                      return !(
                        obj.serialNumber === "" &&
                        obj.modelCode === "" &&
                        obj.manufacturer === "" &&
                        obj.tagNumber === "" &&
                        obj.quality === ""
                      );
                    })
                  );
                }}
                disabled={
                    selectedDeviceList.length === 0 ||
                    additionalReturnDetail && reqDifferentEndUser === "" || 
                    showEndUserFields && (endUser === "" || endUserSiteAddress === "" || endUserCountry === "" || endUserStateProvince === "" || endUserCity === "" || endUserPostalZipCode === "")
                } 
              />
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="deviceServiceRequest">
              <br />
              <br />
              <LabelText text={"System Information"} textClass="ddl-text--h3" />
              <br />
              <p>
                Please give us a few details about your system.
                <br />
                <br />
              </p>
              <div className="systemSelectionMenu">
                <SelectInput
                            label="SYSTEM MODEL"
                            labelClass="app-content-label"
                            value={sysModel}
                            placeholder="PLEASE SELECT"
                            onChange={(o, s, m, h) => {
                              setSysModel(h);
                            }}
                            options={[
                              {
                                greyedOut: false,
                                label: "Liq Hydrogen Metering System",
                                title: "Liq Hydrogen Metering System",
                                value: "Liq Hydrogen Metering System",
                              },
                              {
                                greyedOut: false,
                                label: "Gas Hydrogen Metering System",
                                title: "Gas Hydrogen Metering System",
                                value: "Gas Hydrogen Metering System",
                              },
                              {
                                greyedOut: false,
                                label: "Process Liquid System",
                                title: "Process Liquid System",
                                value: "Process Liquid System",
                              },
                              {
                                greyedOut: false,
                                label: "Process Gas System",
                                title: "Process Gas System",
                                value: "Process Gas System",
                              },
                              {
                                greyedOut: false,
                                label: "Blending System",
                                title: "Blending System",
                                value: "Blending System",
                              },
                            ]}
                            warningMsg=""
							              required
                          />
                <TextInput
                  label="SERIAL NUMBER"
                  onChange={(e) => {
                    setSysSerialNumber(e.target.value);
                  }}
                  required
                  value={sysSerialNumber}
                />
                <TextInput
                  label="PROJECT NUMBER"
                  onChange={(e) => {
                    setSysProject(e.target.value);
                  }}
                  required
                  value={sysProject}
                />
              </div>
              <div className="butonContinue">
                <ButtonInput
                  btnType=""
                  label="CONTINUE"
                  onClick={() => {
                    setActiveInStep(3);
                    setSystem({
                      sysProject: sysProject,
                      sysModel: sysModel,
                      sysSerialNumber: sysSerialNumber,
                    });
                  }}
                  disabled={sysModel !== "" && sysProject !== "" ? false : true}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeviceDetails;
